import { DSNCTldr } from 'shared-definitions/types'
import stylesAlert from './NCAlert.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import styleContent from 'shared-components/styles/content.module.css'
import { htmlReactParse } from 'shared-code/html'
import clsx from 'clsx'
import styles from './NCTldr.module.css'
import composeRefs from '@seznam/compose-react-refs'
import { useScrollableLinksContainer } from 'shared-components/hooks/use-scrollbale-links-container'
import { useTooltipsContainer } from 'shared-components/hooks/use-tooltips-container'

interface NCTldrProps {
  data: DSNCTldr
}

const NCTldr: React.VFC<NCTldrProps> = ({ data }) => (
  <div
    className={clsx(stylesAlert.container, styleUtils.nc, styleContent.content)}
    ref={composeRefs(useScrollableLinksContainer(), useTooltipsContainer())}
  >
    <div className={styles.head}>{'TL;DR'}</div>
    {htmlReactParse(data.html)}
  </div>
)

export default NCTldr
