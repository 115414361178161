import styleContent from '../styles/content.module.css'
import styles from './Fieldset.module.css'
import clsx from 'clsx'
import type { FC, HTMLProps, ReactNode } from 'react'

interface LegendProps extends HTMLProps<HTMLLegendElement> {
  content: string
}

const Legend: FC<LegendProps> = ({ content, ...rest }) => (
  <legend className={clsx(styles.legend, rest.className)} {...rest}>
    <span className={clsx(styles.legendContent)}>{content}</span>
  </legend>
)

interface FieldsetProps extends HTMLProps<HTMLFieldSetElement> {
  legendProps: LegendProps
  children: ReactNode
}

const Fieldset: FC<FieldsetProps> = ({ children, legendProps, ...rest }) => (
  <fieldset className={clsx(styleContent.content, styles.fieldset, rest.className)} {...rest}>
    <Legend {...legendProps} />
    <div className={styles.contentWrapper} role="region">
      <div className={styles.fieldsetContent}>{children}</div>
    </div>
  </fieldset>
)

export default Fieldset
