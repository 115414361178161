import { useEffect } from 'react'
import { logNotice, logWarning } from 'shared-code/log'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { PostContext } from 'shared-components/contexts/PostContext'

export const defaultTaggless: PlaywireTagglessType[] = [
  'bottom_rail',
  'desktop_in_article',
  'trendi_video',
  'relevant_article',
  'flex_leaderboard',
  'site_skin',
]

export function useTaglessAd(input = defaultTaggless): void {
  const { inst } = PlaywireContext.useContainer()
  const { sponsored } = PostContext.useContainer()

  useEffect(() => {
    if (!inst) {
      return
    }

    const inputNorm = sponsored ? [] : input
    const units = inputNorm.map(type => ({ type }))

    const { addUnits, displayUnits } = inst
    void (async () => {
      await clearTaglessUnits(inst)

      if (units.length === 0) {
        return
      }
      try {
        logNotice('Adding units', units)
        await addUnits(units)
        await displayUnits()
      } catch (e) {
        logWarning('Playwire error', e)
      }
    })()
  }, [inst, input, sponsored])
}

async function clearTaglessUnits(runtime: PlaywireRuntimeProps): Promise<void> {
  if (runtime) {
    const units = runtime.getUnits().filter(item => /^pw/.test(item))
    if (units.length) {
      // playwire cleanup
      logNotice('Destroying units', units)
      await runtime.destroyUnits(units)
    }
  }
}
