import { htmlReactParse } from 'shared-code/html'
import styleContent from 'shared-components/styles/content.module.css'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCAlert } from 'shared-definitions/types'
import styles from './NCAlert.module.css'
import composeRefs from '@seznam/compose-react-refs'
import { useScrollableLinksContainer } from 'shared-components/hooks/use-scrollbale-links-container'
import { useTooltipsContainer } from 'shared-components/hooks/use-tooltips-container'

interface NCAlertProps {
  data: DSNCAlert
}

const NCAlert: React.VFC<NCAlertProps> = ({ data }) => (
  <div
    className={clsx(styles.container, styleContent.content, styleUtils.nc)}
    ref={composeRefs(useScrollableLinksContainer(), useTooltipsContainer())}
  >
    {htmlReactParse(data.html)}
  </div>
)

export default NCAlert
