import styles from './NCPostsList.module.css'
import PostRow from 'shared-components/posts/PostRow'
import { DSNCPostsList, DSPostShortWithAuthor } from 'shared-definitions/types'
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import { getds } from 'ds'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { useCallback, useRef } from 'react'
import clsx from 'clsx'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'

interface NCPostsListProps {
  data: DSNCPostsList
}

const NCPostsList: React.VFC<NCPostsListProps> = ({ data }) => {
  const { ts } = CommonDataContext.useContainer()
  const { ready, lg } = ResponsiveContext.useContainer()
  const ref = useRef<HTMLDivElement>(null)

  const pageLoader = useCallback(
    async (pageNumber: number, perPage: number) => {
      const dataSource = await getds({ ts })
      const result = await dataSource.getPostsList(data.taxonomy, pageNumber, perPage)
      return result.data.items
    },
    [data.taxonomy, ts]
  )

  return (
    <LoadMoreList
      ref={ref}
      render={useCallback(
        (item: DSPostShortWithAuthor, index) => (
          <PostRow key={index} post={item} />
        ),
        []
      )}
      className={clsx(styles.list)}
      initial={data.posts}
      loader={pageLoader}
      onLoad={useCallback(() => {
        if (!ready || !lg) {
          return
        }

        const { current } = ref
        if (!current) {
          return
        }

        current.scrollTop = current.scrollHeight
      }, [ready, lg])}
    />
  )
}

export default NCPostsList
