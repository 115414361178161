/* eslint-disable react/display-name */
import clsx from 'clsx'
import React, { useCallback } from 'react'
import EditButton from 'shared-components/service/EditButton'
import stylesDeals from 'shared-components/styles/deals.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import AppImage from 'shared-components/ui/AppImage'
import Score from 'shared-components/ui/Score'
import SplitterLine from 'shared-components/ui/SplitterLine'
import { DSNCDealsLarge } from 'shared-definitions/types'
import AccordionDeal, { StandardDealButton, StandardDealButtonProps } from '../layout/AccordionDeal'
import styles from './NCDealsLarge.module.css'

interface NCDealsLargeProps {
  data: DSNCDealsLarge
}

const NCDealsLarge = React.forwardRef<HTMLDivElement, NCDealsLargeProps>(({ data }, ref) => (
  <>
    <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
    <div
      {...data.dataAttrs}
      ref={ref}
      className={clsx(styles.container, stylesDeals.bg, stylesDeals.border, styleUtils.nc)}
    >
      {data.anchor && (
        <a id={data.anchor} className={styleUtils.anchor}>
          {data.title}
        </a>
      )}
      {data.image ? (
        <AppImage
          layout="raw"
          className={clsx(styles.imageContainer, stylesDeals.border)}
          image={data.image}
          sizes={[
            { bp: 'lg', val: 'rem(200)' },
            { bp: 'sm', val: 'calc(100vw / 3)' },
            'calc(100vw - rem(40))',
          ]}
        />
      ) : null}

      <div className={styles.title}>{data.title}</div>
      {data.tags.length ? <div className={styles.tags}>{data.tags.join(' • ')}</div> : null}
      {data.score ? <Score className={styles.score} score={data.score} /> : null}
      <SplitterLine className={styles.separator} variant="bright" />
      {data.subtitle ? <div className={styles.subtitle}>{data.subtitle}</div> : null}
      {data.text ? <div className={styles.text}>{data.text}</div> : null}

      <div className={styles.buttonsGrid}>
        <AccordionDeal
          data={data.buttons}
          view="bordered"
          asButton={useCallback(
            (injectedProps: StandardDealButtonProps) => (
              <StandardDealButton data-sel="large-deal-button" {...injectedProps} />
            ),
            []
          )}
        />
      </div>
    </div>
  </>
))

export default NCDealsLarge
