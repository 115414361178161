import { DSNCTable, DSTableCell } from 'shared-definitions/types'
import clsx from 'clsx'
import styles from './NCTable.module.css'
import BreakedText from 'shared-components/service/BreakedText'
import styleUtils from 'shared-components/styles/util.module.css'
import styleContent from 'shared-components/styles/content.module.css'
import { htmlReactParse } from 'shared-code/html'
import EditButton from 'shared-components/service/EditButton'

interface NCTableProps {
  data: DSNCTable
}

const NCTable: React.VFC<NCTableProps> = ({ data }) => (
  <>
    <EditButton pLink={data.pLinkEdit} elevated pLinkRequestUpdate={null} />
    <div className={clsx(styleUtils.nc, styleContent.content)}>
      <table>
        <thead>
          <tr>
            {data.headings.map((item, index) => (
              <th colSpan={item.cols} rowSpan={item.rows} key={index}>
                {htmlReactParse(item.html)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.cells.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  colSpan={cell.cols}
                  rowSpan={cell.rows}
                  key={cellIndex}
                  className={clsx({ [styles.hidden]: cell.hidden })}
                >
                  {inlineHeaders(data.headings, cell).map((item, hIndex) => (
                    <span key={hIndex} className={styles.tableLabel}>
                      {htmlReactParse(item)}
                    </span>
                  ))}
                  <div>
                    <BreakedText>{cell.html}</BreakedText>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
)

export default NCTable

function inlineHeaders(headers: DSTableCell[], cell: DSTableCell): string[] {
  if (headers.length <= 2 || !headers[cell.colIndex].html) {
    return []
  }
  const cols = cell.cols ?? 1
  const result: string[] = []

  for (let i = 0; i < cols; i++) {
    const newIndex = cell.colIndex + i
    if (newIndex < headers.length) {
      result.push(headers[newIndex].html)
    }
  }

  return result
}
