import styles from './ProductSave.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { wrapWithCurrency, priceFormat } from 'shared-code/format'

interface ProductSaveProps extends BasicStyledComponent {
  price: number
  currency: string
  discount: number
}

const ProductSave: React.VFC<ProductSaveProps> = ({ currency, discount, price, className }) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.msrp}>{'MSRP'}</div>
    <div className={styles.save}>{'SAVE'}</div>
    <div className={styles.price}>{wrapWithCurrency(priceFormat(price), currency)}</div>
    <div className={styles.discount}>{wrapWithCurrency(priceFormat(discount), currency)}</div>
  </div>
)

export default ProductSave
