import { useCallback } from 'react'
import { DSNCDealsDetailed } from 'shared-definitions/types'
import clsx from 'clsx'
import ArrowedButton from 'shared-components/buttons/ArrowedButton'
import styleUtils from 'shared-components/styles/util.module.css'
import stylesDeals from 'shared-components/styles/deals.module.css'
import stylesCommon from 'shared-components/styles/deals-widget.module.css'
import styles from './NCDealsDetailed.module.css'
import SplitterLine from 'shared-components/ui/SplitterLine'
import Score from 'shared-components/ui/Score'
import ProductSave from 'shared-components/deals/ProductSave'
import EditButton from 'shared-components/service/EditButton'
import AccordionDeal, { StandardDealButton, StandardDealButtonProps } from '../layout/AccordionDeal'

interface NCDealsDetailedProps {
  data: DSNCDealsDetailed
}

const NCDealsDetailed: React.VFC<NCDealsDetailedProps> = ({ data }) => {
  const { discount, current, currency, isFree } = data.buttons[0].price

  return (
    <>
      <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
      <div {...data.dataAttrs} className={clsx(styles.container, styleUtils.nc)}>
        {data.anchor && (
          <a id={data.anchor} className={styleUtils.anchor}>
            {data.buttons[0].link.label}
          </a>
        )}
        <div className={clsx(styles.widget, stylesDeals.bg, stylesDeals.border)}>
          <div className={styles.info}>
            <div className={clsx(stylesCommon.titleFont)}>{data.title}</div>
            {data.tags.length ? <div className={styles.tags}>{data.tags.join(' • ')}</div> : null}
            {data.score ? <Score className={styles.score} score={data.score} /> : null}
            <SplitterLine className={styles.separator} variant="bright" />
            {data.refLink ? (
              <ArrowedButton className={stylesCommon.refLinkDesktop} {...data.refLink.pLink}>
                {data.refLink.label}
              </ArrowedButton>
            ) : null}
          </div>
          {discount && current && !isFree ? (
            <ProductSave
              className={styles.priceSave}
              price={current}
              discount={discount}
              currency={currency}
            />
          ) : null}
          <AccordionDeal
            data={data.buttons}
            withDiscount={false}
            view="bordered"
            asButton={useCallback(
              (injectedProps: StandardDealButtonProps) => (
                <StandardDealButton
                  className={styles.button}
                  data-sel="list-deal-button"
                  {...injectedProps}
                />
              ),
              []
            )}
          />
        </div>

        {data.refLink ? (
          <ArrowedButton className={stylesCommon.refLink} {...data.refLink.pLink}>
            {data.refLink.label}
          </ArrowedButton>
        ) : null}
      </div>
    </>
  )
}

export default NCDealsDetailed
