import { DSNCAdClassed } from 'shared-definitions/types'
import styles from './NCAdClassed.module.css'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import { PostContext } from 'shared-components/contexts/PostContext'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'

interface NCAdClassedProps {
  data: DSNCAdClassed
}

const NCAdClassed: React.VFC<NCAdClassedProps> = ({ data }) => {
  const { sponsored } = PostContext.useContainer()
  const { enabled, adClassList } = PlaywireContext.useContainer()
  if (sponsored || !enabled) {
    return null
  }

  return (
    <div
      className={clsx(...adClassList, styleUtils.mobileFull, data.className, {
        [styles.desktop]: data.visible === 'desktop',
        [styles.mobile]: data.visible === 'mobile',
        [styles.video]: data.type === 'video',
      })}
    >
      <div />
    </div>
  )
}

export default NCAdClassed
