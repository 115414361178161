import SoundIcon from 'shared-components/service/SoundIcon'
import IconLabel from 'shared-components/ui/IconLabel'
import { BasicStyledComponent, DSSoundLabel } from 'shared-definitions/types'
import styles from './SoundLabel.module.css'

interface SoundLabelProps extends BasicStyledComponent {
  label: DSSoundLabel
}

const SoundLabel: React.VFC<SoundLabelProps> = ({ className, label }) => (
  <IconLabel
    className={className}
    title={label.title}
    iconEl={<SoundIcon icon={label.icon} className={styles.icon} />}
  />
)

export default SoundLabel
