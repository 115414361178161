import { createElement } from 'react'
import {
  DSResource,
  MapDiscriminatedComponent,
  NCComponentInterface,
} from 'shared-definitions/types'

// todo: redo types

export function componentMapper<T extends DSResource>(
  map: MapDiscriminatedComponent<T, 'resource'>
): React.VFC<NCComponentInterface<T>> {
  return function nc({ data, tpl, index }) {
    const render = map[data.resource as T['resource']]
    if (!render) {
      return null
    }

    return createElement(render, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: data as any,
      tpl,
      index,
      nc,
    })
  }
}
