/* eslint-disable react/display-name */
import styles from './Nodes.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSNodes } from 'shared-definitions/types'
import React from 'react'

interface NodesProps extends BasicStyledComponent {
  table: DSNodes
  limit?: number
  variant: 'expanded' | 'collapsed'
}

type ValueOrArray<T> = T | T[]

interface ValueProps {
  name: string | null
  useOffset?: boolean
  value: ValueOrArray<string | number>
}

const Value: React.VFC<ValueProps> = ({ name, value, useOffset }) => {
  if (!Array.isArray(value)) {
    return (
      <>
        {!!name && <span className={styles.name}>{name}: </span>}
        {value}
      </>
    )
  }

  return (
    <>
      {!!name && <span className={styles.name}>{name}:</span>}
      <ul className={clsx(styles.valueList, { [styles.linedList]: useOffset })}>
        {value.map((item, index) => (
          <li className={styles.valueItem} key={index}>
            {item}
          </li>
        ))}
      </ul>
    </>
  )
}

const Nodes = React.forwardRef<HTMLDivElement, NodesProps>(
  ({ className, table, limit, variant }, ref) => {
    const printTable = limit ? table.filter(i => i.featured).slice(0, limit) : table
    return (
      <div
        ref={ref}
        className={clsx(styles.container, className, {
          [styles.collapsed]: variant === 'collapsed',
          [styles.expanded]: variant === 'expanded',
        })}
      >
        {printTable.map((nodeItem, nodeIndex) => (
          <React.Fragment key={nodeIndex}>
            <div className={styles.nodeName}>{nodeItem.name}</div>
            <div className={styles.nodeValues}>
              {nodeItem.value.map((value, valueIndex, arr) => (
                <div className={styles.nodeValueComplex} key={valueIndex}>
                  {arr.length > 1 && <span className={styles.complexName}>{value.name}</span>}
                  {value.value.map((complexItem, complexIndex) => (
                    <span
                      key={complexIndex}
                      className={clsx(styles.complexValue, {
                        [styles.featured]: complexItem.featured,
                      })}
                    >
                      <Value
                        value={complexItem.value}
                        useOffset={arr.length > 1 || value.value.length > 1}
                        name={value.value.length > 1 ? complexItem.name : null}
                      />
                    </span>
                  ))}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }
)

export default Nodes
