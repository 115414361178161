/* eslint-disable react/display-name */
import clsx from 'clsx'
import React from 'react'
import Linked from 'shared-components/service/Linked'
import AppImage from 'shared-components/ui/AppImage'
import { BasicStyledComponent, DSDataAttrs, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './PostCardRelated.module.css'

interface PostCardRelatedProps extends BasicStyledComponent, DSDataAttrs {
  post: DSPostShortWithAuthor
  hideCategory?: boolean
  unresponsive?: boolean
}

const PostCardRelated: React.VFC<PostCardRelatedProps> = React.memo(
  ({ hideCategory, post, className, unresponsive, ...props }) => (
    <Linked
      className={clsx(styles.container, className, { [styles.unresponsive]: unresponsive })}
      {...post.pLink}
      {...props}
    >
      <div className={styles.info}>
        <div className={styles.title}>{post.title}</div>
        {(!hideCategory && post.category) || post.sponsored ? (
          <div className={styles.tag}>{post.sponsored ? 'Promoted' : post.category}</div>
        ) : null}
      </div>
      {post.image ? (
        <AppImage
          className={styles.img}
          image={post.image}
          layout="raw"
          sizes={[{ bp: 'sm', val: 282 }, 90]}
        />
      ) : null}
    </Linked>
  )
)

export default PostCardRelated
