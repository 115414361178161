import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import { BasicStyledComponent, DSImage, LinkedLinkProps } from 'shared-definitions/types'
import styles from './PreviewPostLink.module.css'
import AppImage from 'shared-components/ui/AppImage'

interface PostProps {
  title: string
  subtitle: string | null
  image: DSImage
}

interface PreviewPostLinkProps extends BasicStyledComponent, LinkedLinkProps {
  post: PostProps
  responsive?: boolean
}

const PreviewPostLink: React.VFC<PreviewPostLinkProps> = ({
  href,
  target,
  responsive,
  post,
  className,
}) => (
  <Linked
    className={clsx(className, styles.container, { [styles.responsive]: responsive })}
    href={href}
    target={target}
  >
    <div className={styles.title}>{post.title}</div>
    <div className={styles.subtitle}>{post.subtitle}</div>
    {post.image ? (
      <AppImage
        sizes={responsive ? [{ bp: 'md', val: 160 }, 90] : 90}
        layout="raw"
        className={styles.imageContainer}
        image={post.image}
      />
    ) : (
      <div className={styles.imageContainer} />
    )}
  </Linked>
)

export default PreviewPostLink
